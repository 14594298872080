<template>
  <b-modal
    id="indicator-modal"
    v-model="formShow"
    title="Upload Indicator"
    cancel-title="CANCEL"
    ok-title="SAVE"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <b-row class="mb-4">
        <b-col cols="12">
          <b-form-select
            v-model="form.type"
            :options="testTypeList"
            :state="$v.form.$anyDirty ? !$v.form.type.$anyError : null"
          >
            <template #first>
              <b-form-select-option :value="null">
                Select a test
              </b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.type.required : null">
            test type is required
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row v-if="form.type===99">
        <b-col cols="12">
          <b-form-group
            label="Test Type Detail"
            class="mb-4"
            :state="$v.form.$anyDirty ? !$v.form.type_other.$anyError : null"
          >
            <b-form-input
              v-model="form.type_other"
            />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.type_other.error : null">
              Test Type Detail is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="!preview">
        <!-- <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.file.error : null">
          file is required
        </b-form-invalid-feedback> -->
        <div id="drop-area" @drop="onFileDrop">
          <input id="fileElem" ref="fileUpload" type="file" accept="image/*" @change="onFilePicked">
          <div
            class="d-flex justify-content-center align-items-center"
            @dragover.prevent
            @drop.prevent
            @click="onFileUploadClick"
          >
            <b-row @dragover.prevent @drop.prevent>
              <b-col cols="12" class="d-flex justify-content-center align-items-center" @dragover.prevent @drop.prevent>
                <img alt="file upload" src="~@/assets/images/icon-others-photo.png" @dragover.prevent @drop.prevent>
              </b-col>
              <b-col cols="12" class="d-flex justify-content-center align-items-center" @dragover.prevent @drop.prevent>
                Darg and Drop file
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <b-row v-if="preview">
        <b-col cols="12" class="d-flex justify-content-end mb-4">
          <b-button variant="primary" class="btn-width" @click="onFileCancel">
            CANCEL
          </b-button>
        </b-col>
        <b-col cols="12">
          <b-img :src="preview" alt="image preview" style="width:100%" />
        </b-col>
        <b-col cols="12">
          <b-form-group label="Result" label-cols="2" class="mb-0">
            <b-form-radio-group v-model="form.result" class="pt-3" :options="resultOptions" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.result.required : null">
              result is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>

import { required } from 'vuelidate/lib/validators'
import testTypeList from '@/common/testTypeList'
import { createIndicator, updateIndicator } from '@/api/indicator'

export default {
  name: 'IndicatorModal',
  props: {
    cycleId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data () {
    return {
      form: {
        id: null,
        type: null,
        type_other: '',
        file: null,
        result: 0
      },
      preview: '',
      resultOptions: [
        { text: 'Pass', value: 0 },
        { text: 'Fail', value: 1 }
      ],
      formShow: false,
      newImage: false,
      edit: false
    }
  },
  validations () {
    return {
      form: {
        type: { required },
        // file: {
        //   error () {
        //     return (!this.edit && this.form.file) || (this.edit && this.preview)
        //   }
        // },
        result: { required },
        type_other: {
          error () {
            return this.form.type !== 99 ||
        (this.form.type === 99 && this.form.type_other !== undefined && this.form.type_other)
          }
        }
      }
    }
  },
  computed: {
    testTypeList () {
      return testTypeList
    }
  },
  methods: {
    show (data) {
      this.$bvModal.show('indicator-modal')
      this.form = {
        id: null,
        type: null,
        type_other: '',
        file: null,
        result: 0
      }
      this.preview = ''
      this.newImage = false
      this.edit = false
      if (data) {
        this.form = { ...data }
        this.preview = data.file
        this.edit = true
      }
      this.formShow = true
      this.$v.form.$reset()
    },
    hide () {
      this.$bvModal.hide('indicator-modal')
      this.formShow = false
    },
    submit (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      // todo: upload image
      const data = {
        id: this.form.id,
        cycle_id: this.cycleId,
        file: this.preview,
        type: this.form.type,
        type_other: this.form.type_other,
        result: this.form.result
      }
      if (this.edit) {
        updateIndicator(data).then(res => {
          this.formClose()
        })
      } else {
        createIndicator(data).then(res => {
          this.formClose()
        })
      }
    },
    onFileUploadClick () {
      this.$refs.fileUpload.click()
    },
    onFileDrop (e) {
      e.preventDefault()
      e.stopPropagation()
      this.onFilePicked(e)
    },
    async onFilePicked (e) {
      e.preventDefault()
      const file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0]
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.preview = fileReader.result
        this.newImage = true
      })
      fileReader.readAsDataURL(file)
      this.form.file = file
    },
    onFileCancel () {
      this.preview = ''
      this.form.file = null
    },
    formClose () {
      this.$emit('update')
      this.hide()
    }
  }
}
</script>
<style lang="css" scoped>
#drop-area {
    height: 300px;
    padding-top: 84px;
    border-radius: 20px;
    box-shadow: inset -10px -10px 10px 0 rgba(255, 255, 255, 0.7), inset 10px 10px 10px 0 rgba(174, 174, 192, 0.2);
    background-color: #f0f0f5;
}
#fileElem {
    display: none;
}
</style>
