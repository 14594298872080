import apiService from '@/common/api.service'

export function getSpecifyist (id) {
  return apiService({
    url: '/api/cycle/list/specify',
    method: 'get',
    params: { id }
  })
}

export function getCycleById (id) {
  return apiService({
    url: '/api/cycle',
    method: 'get',
    params: { id }
  })
}

export function createCycle (data) {
  return apiService({
    url: '/api/cycle',
    method: 'post',
    data
  })
}

export function completeCycle (data) {
  return apiService({
    url: '/api/cycle/complete',
    method: 'patch',
    data
  })
}

export function updateIndicator (data) {
  return apiService({
    url: '/api/cycle/indicator',
    method: 'patch',
    data
  })
}

export function getExportById (id) {
  return apiService({
    url: '/api/cycle/export',
    method: 'get',
    responseType: 'blob',
    params: { id }
  })
}

export function getExportPackageById (id) {
  return apiService({
    url: '/api/cycle/export/package',
    method: 'get',
    responseType: 'blob',
    params: { id: id }
  })
}
