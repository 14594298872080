<template>
  <b-row class="justify-content-center">
    <b-col cols="auto">
      <font-awesome-icon
        v-if="index>0"
        class="btn-nav"
        :icon="['fas', 'caret-left']"
        fixed-width
        @click="onPrevClick"
      />
    </b-col>
    <b-col cols="auto">
      <h3>{{ name }}</h3>
    </b-col>
    <b-col cols="auto">
      <font-awesome-icon
        v-if="index<total-1"
        class="btn-nav"
        :icon="['fas', 'caret-right']"
        fixed-width
        @click="onNextClick"
      />
    </b-col>
  </b-row>
</template>
<script>

export default {
  name: 'NamePagination',
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true,
      default: 0
    },
    total: {
      type: Number,
      required: true
    }
  },
  data () {
    return { index: 0 }
  },
  mounted () {
    this.index = this.value
  },
  methods: {
    onPrevClick () {
      if (this.index > 0) { this.updateValue(-1) }
    },
    onNextClick () {
      if (this.index < this.total) { this.updateValue(1) }
    },
    updateValue (num) {
      this.index += num
      this.$emit('input', this.index)
      this.$emit('change')
    }
  }
}
</script>
<style scoped>
.btn-nav{
    cursor: pointer;
}
</style>
