<template>
  <b-row>
    <b-col v-if="autoclave" cols="4">
      <div v-if="autoclave" class="areawrap">
        <div class="d-flex justify-content-between align-items-end mb-2">
          <h2 class="mb-0">
            {{ autoclave.name }}
          </h2>
        </div>
        <h4 class="caption">
          {{ autoclave.number }}
        </h4>
        <hr>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Model" label-class="text-primary mb-1">
              {{ model }}
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Warranty" label-class="text-primary mb-1">
              {{ autoclave.warranty }}
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Wifi Connection" label-class="text-primary mb-1">
              {{ autoclave.linked?'Connect':'Disconnect' }}
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Count" label-class="text-primary mb-1">
              {{ emptyString(autoclave.count) }}
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Product Status" label-class="text-primary mb-1">
              {{ emptyString(autoclave.status) }}
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Station" label-class="text-primary mb-1">
              {{ emptyString(autoclave.work_station) }}
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="selectedHistory.type!==0" class="areawrap mt-4 py-4">
        <div class="d-flex justify-content-between align-items-end">
          <h2 class="mb-0">
            Indicator
          </h2>
          <b-link v-if="indicators.length<5" class="text-gray-500" href="#" @click.prevent="onUploadClick">
            <font-awesome-icon
              :icon="['fas', 'plus']"
              fixed-width
            />
          </b-link>
        </div>
        <template v-if="selectedIndicator">
          <b-row class="mt-4">
            <b-col cols="4" class="text-primary">
              測試項目
            </b-col>
            <b-col cols="8" class="pl-0">
              <div class="d-flex justify-content-between">
                {{ testType }}
                <div>
                  <b-link class="text-gray-500" href="#" @click.prevent="onEditIndicatorClick">
                    <font-awesome-icon
                      :icon="['fas', 'pen']"
                      fixed-width
                    />
                  </b-link>
                  <b-link class="text-gray-500 ml-2" href="#" @click.prevent="onDeleteIndicatorClick">
                    <font-awesome-icon
                      :icon="['fas', 'trash']"
                      fixed-width
                    />
                  </b-link>
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <img :src="selectedIndicator.file" alt="indicator image" style="width:100%" class="mt-4">
            </b-col>
            <b-col cols="4" class="text-primary mt-2">
              Result
            </b-col>
            <b-col cols="8" class="pl-0 mt-2">
              {{ selectedIndicator.result===0?'Pass':'Failed' }}
            </b-col>
            <b-col cols="4" class="text-primary mt-2">
              Uploader
            </b-col>
            <b-col cols="8" class="pl-0 mt-2">
              {{ selectedIndicator.uploaded_by }}
            </b-col>
            <b-col cols="4" class="text-primary mt-2">
              Upload Time
            </b-col>
            <b-col cols="8" class="pl-0 mt-2">
              {{ selectedIndicator.uploaded_at }}
            </b-col>
          </b-row>
        </template>
      </div>
      <div
        v-if="indicators.length > 0"
        class="custom-pagination d-flex justify-content-between hasprev mb-4"
        :class="{
          hasnext: selectedIndicatorIndex !== indicators.length,
          hasprev: selectedIndicatorIndex !== 1,
        }"
      >
        <b-pagination
          v-model="selectedIndicatorIndex"
          pills
          hide-goto-end-buttons
          hide-ellipsis
          :total-rows="indicators.length"
          :per-page="1"
          :limit="5"
        >
          <template #page>
            <span />
          </template>
          <template #prev-text>
            <font-awesome-icon :icon="['fas', 'caret-left']" fixed-width />
          </template>
          <template #next-text>
            <font-awesome-icon :icon="['fas', 'caret-right']" fixed-width />
          </template>
        </b-pagination>
        <row-count :total-rows="indicators.length" :row-pre-page="1" :current-page="selectedIndicatorIndex" />
      </div>
    </b-col>
    <b-col v-if="selectedHistory" cols="8">
      <div v-if="selectedHistory" class="areawrap" style="position: relative;">
        <b-row>
          <b-col cols="12" class="row-title">
            <div class="vip-icon">
              <b-button
                variant="secondary"
                class="text-gray-500 mr-4"
                @click="onDownloadClick()"
              >
                <font-awesome-icon :icon="['fas', 'download']" fixed-width />
              </b-button>
              <b-button
                variant="secondary"
                :class="`${selectedHistory.vip?'text-warning': 'text-gray-500' }`"
              >
                <font-awesome-icon :icon="['fas', 'bookmark']" fixed-width />
                VIP
              </b-button>
            </div>

            <name-pagination
              v-model="selectedHistoryIndex"
              :name="historyDetailTitle"
              :total="historyList.length"
              @change="onHistoryDetailChange"
            />
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col cols="12">
            <h3 class="mb-4">
              Data
            </h3>
            <b-textarea rows="20" readonly :value="selectedHistory.data" />
          </b-col>
        </b-row>
      </div>
      <indicator-modal ref="indicatorModal" :cycle-id="selectedHistory.id" @update="onIndicatorUpdate" />
    </b-col>
  </b-row>
</template>
<script>
import modelList from '@/common/modelList'
import cycleTypeList from '@/common/cycleTypeList'
import testTypeList from '@/common/testTypeList'
import { getCycleById, getExportById } from '@/api/cycle'
import { getIndicatorList, deleteIndicator } from '@/api/indicator'
import NamePagination from '@/components/common/namePagination'
import IndicatorModal from '@/components/product/indicatorModal'
import RowCount from '@/components/common/rowCount'

export default {
  name: 'ProductDetail',
  components: {
    NamePagination,
    IndicatorModal,
    RowCount
  },
  data () {
    return {
      autoclave: null,
      historyList: [],
      selectedHistory: null,
      selectedHistoryIndex: 0,
      selectedIndicatorIndex: 1,
      indicators: []
    }
  },
  computed: {
    model () {
      return modelList.map(p => p.options).flat().find(m => m.value === this.autoclave.model)?.text
    },
    testType () {
      if (this.selectedIndicator.type === null) return ''
      const type = testTypeList.find(m => m.value === this.selectedIndicator.type)?.text
      if (this.selectedIndicator.type === 99) { return `${type} - ${this.selectedIndicator.type_other}` }
      return type
    },
    historyDetailTitle () {
      const formated = this.$dayjs(this.selectedHistory.datetime).format('MMM D, YYYY HH:mm:ss')
      return formated
    },
    historySummaryFields () {
      return ['step', 'time', 'temp', 'pres']
    },
    programName () {
      if (this.selectedHistory.type === 0) return this.emptyString(this.selectedHistory.program)
      return cycleTypeList.map(p => p.options).flat().find(m => m.value === this.selectedHistory.type)?.text
    },
    selectedIndicator () {
      if (this.indicators.length === 0) { return null } else if (this.indicators.length < this.selectedIndicatorIndex) {
        return this.indicators[this.indicators.length - 1]
      }
      return this.indicators[this.selectedIndicatorIndex - 1]
    }
  },
  created () {
    this.autoclave = this.$store.state.product.selected
    if (!this.autoclave) this.$router.push({ name: 'Product' })
    else {
      this.historyList = this.$store.state.product.historyList
      this.selectedHistory = this.$store.state.product.selectedHistory
      this.selectedHistoryIndex = this.historyList.findIndex(h => h.id === this.selectedHistory.id)
      this.init()
    }
  },
  methods: {
    async init () {
      await this.getCycleDetail(this.selectedHistory.id)
    },
    async getCycleDetail (id) {
      getCycleById(id).then(res => {
        this.selectedHistory = res.data.data.cycle
        this.selectedHistory.data = this.selectedHistory.data.replace('\\n', '\n')
      })
      this.getIndicatorList(id)
    },
    getIndicatorList (id) {
      getIndicatorList(id).then(res => {
        this.indicators = res.data.data
      })
    },
    onHistoryDetailChange () {
      const history = { ...this.historyList[this.selectedHistoryIndex] }
      this.getCycleDetail(history.id)
    },
    onUploadClick () {
      this.$refs.indicatorModal.show(null)
    },
    onEditIndicatorClick () {
      this.$refs.indicatorModal.show(this.selectedIndicator)
    },
    onDeleteIndicatorClick () {
      deleteIndicator(this.selectedIndicator.id).then(() => {
        this.onIndicatorUpdate()
      })
    },
    onIndicatorUpdate () {
      this.getIndicatorList(this.selectedHistory.id)
    },
    emptyString (val, unit = '') {
      return !val ? '-' : val + unit
    },
    onDownloadClick () {
      getExportById(this.selectedHistory.id).then((res) => {
        const disposition = res.headers['content-disposition']
        const filename = disposition.substring(disposition.indexOf('filename=') + 9, disposition.length).replace(/"/g, '')
        const fileUrl = URL.createObjectURL(res.data)
        const fileLink = document.createElement('a')

        fileLink.href = fileUrl
        fileLink.download = filename

        fileLink.click()
      })
    }
  }
}
</script>
<style scoped lang="scss">
.b-table-top-row td, .caption-th{
  font-size: 12px;
  color:rgba(84, 90, 93, 0.7);
}
.step-td{
  color:#0088d1;
  font-size: 12px;
  font-weight: 500;
}
.vip-icon{
  position: absolute;
  left: 16px;

  button{
  font-size: 16px;
  }
}
.row-title{
  height: 40px;
}
</style>
