import apiService from '@/common/api.service'

export function getIndicatorList (id) {
  return apiService({
    url: 'api/indicator/list/specify',
    method: 'get',
    params: { cycle_id: id }
  })
}

export function createIndicator (data) {
  return apiService({
    url: '/api/indicator',
    method: 'post',
    data
  })
}

export function updateIndicator (data) {
  return apiService({
    url: '/api/indicator',
    method: 'patch',
    data
  })
}

export function deleteIndicator (id) {
  return apiService({
    url: '/api/indicator',
    method: 'delete',
    params: { id }
  })
}
